import {notify} from "@/helpers/notifyHelper";
import api from '@/api/positions-submitting'
import apiPosition from '@/api/positions'

export default {
  data: () => ({
    multiple: false,
    dragover: false,
    valid: true,
    nameRules: [
      v => !!v || 'Name is required',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    textareaRules: [
      v => !!v || 'Textarea is required',
    ],
    inputFileRules: '',
    resume: null,
    form: {
      positionId: null,
      name: '',
      email: '',
      message: '',
      resume: null
    },
    uploadedFiles: []
  }),
  computed: {
    positionUrl() {
      return this.$route.params.urlName
    }
  },
  created() {
    if (this.positionUrl) {
      apiPosition.getByUrl(this, this.positionUrl)
        .then(res => {
          this.form.positionId = res.data[0].id
        })
    }
  },
  methods: {
    onDrop(e) {
      this.dragover = false;
      if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
      if (!this.multiple && e.dataTransfer.files.length > 1) {
        notify(this, 'error', 'Only one file can be uploaded at a time..')
      } else {
        this.form.resume = e.dataTransfer.files[0]
      }
    },
    submit(e) {
      this.inputFileRules = []
      e.preventDefault();
      if (!this.form.resume) {
        this.inputFileRules.push('Browse your resume is required')
      }
      if (this.$refs.form.validate() && this.form.resume) {
        let data = new FormData()
        Object.keys(this.form).map(key => {
          data.append(key, this.form[key])
        })

        api.create(this, data)
          .then(() => {
            notify(this, 'success', "We will contact you soon")
            this.$refs.form.reset()
            this.$refs.fileInput.value = null;
            this.form = {
              name: '',
              email: '',
              message: '',
              resume: null
            }

          })
      }
    },
    chooseImage() {
      this.$refs.fileInput.click()
    },
    deleteFile() {
      this.form.resume = null
      this.$refs.fileInput.value = null;
    },
    onSelectFile() {
      const input = this.$refs.fileInput
      const files = input.files
      if (files && files[0]) {
        this.form.resume = files[0]
      }
    }
  }
}
